<template>
  <TagbyActionCardV2>
    <CancelOrderButton />
    <TakebackOrderButton />
    <SetPaidButton />
    <UpdateShpButton />
    <CancelAccountButton />
    <CreateMemoButton />
    <ResetPasswordButton />
    <RevertCanceledOrderGroupButton />
    <ChargeAndPayButton />
  </TagbyActionCardV2>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyActionCardV2 from "@/components/TagbyActionCardV2.vue"
import CancelOrderButton from "./CancelOrderButton.vue"
import CreateMemoButton from "./CreateMemoButton.vue"
import SetPaidButton from "./SetPaidButton.vue"
import ResetPasswordButton from "./ResetPasswordButton.vue"
import UpdateShpButton from "./UpdateShpButton.vue"
import RevertCanceledOrderGroupButton from "./RevertCanceledOrderGroupButton.vue"
import TakebackOrderButton from "./TakebackOrderButton.vue"
import ChargeAndPayButton from "./ChargeAndPayButton.vue"
import CancelAccountButton from "./CancelAccountButton.vue"

export default defineComponent({
  components: {
    TagbyActionCardV2,
    CancelOrderButton,
    CreateMemoButton,
    SetPaidButton,
    ResetPasswordButton,
    UpdateShpButton,
    RevertCanceledOrderGroupButton,
    TakebackOrderButton,
    ChargeAndPayButton,
    CancelAccountButton,
  },
  setup() {

  },
})
</script>
