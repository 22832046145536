<template>
  <div>
    <BCard no-body>
      <BTable
        :fields="payFields"
        :items="state.payList"
        responsive
      >
        <template #cell(amount)="data">
          {{ resolveMoney(data.value) }}
        </template>

        <template #cell(pay_type)="data">
          {{ resolvePayType(data.value) }}
        </template>

        <template #cell(etc)="data">
          <span v-if="data.item.has_pid">
            PID: {{ data.item.pid }}
          </span>
          <span v-else-if="data.item.has_depositor">
            입금자: {{ data.item.depositor_name }}
            <span v-if="data.item.has_cash_recipient">
              ({{ data.item.cash_receipt_type }} / {{ data.item.cash_receipt_id_type }} / {{ data.item.cash_receipt_id }})
            </span>
            <span v-else>
              (미신청)
            </span>
          </span>
          <span v-else-if="data.item.has_takeback_account">
            은행: {{ data.item.bank_name }} / 계좌번호: {{ data.item.bank_no }} / 예금주: {{ data.item.bank_holder }}
          </span>

        </template>

        <template #cell(updated_at)="data">
          <TagbyDatetimeColumn :value="data.value" />
        </template>
      </BTable>
    </BCard>
  </div>
</template>

<script>
import {
  defineComponent,
} from '@vue/composition-api'
import {
  BCard,
  BTable,
  BLink,
} from 'bootstrap-vue'
import state from '../../state'
import getters from '../../getters'
import useResolveValue from '@/utils/resolveValue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BLink,
    TagbyDatetimeColumn,
  },
  setup() {
    const {
      resolveMoney,
    } = useResolveValue()

    const payFields = [
      { key: 'idx', label: 'idx' },
      { key: 'pay_type', label: '결제수단' },
      { key: 'amount', label: '금액' },
      { key: 'state', label: '상태' },
      { key: 'etc', label: '기타' },
      { key: 'updated_at', label: '결제일시' },
    ]

    const resolvePayType = value => {
      if (value === 'CARD') {
        return '카드'
      }
      if (value === 'NICEPAY_AUTH') {
        return '나이스페이'
      }
      if (value === 'ACCOUNT') {
        return '무통장입금'
      }
      if (value === 'NAVERPAY_AUTH') {
        return '네이버페이'
      }
      if (value === 'POINT') {
        return '포인트'
      }
      return '알수없음'
    }

    return {
      state,
      getters,
      payFields,
      resolveMoney,
      resolvePayType,
    }
  },
})
</script>
