<template>
  <TagbyActionButtonV2
    :isValid="true"
    variant="outline-danger"
    text="주문취소"
    @click="turnOnSidebar"
  />
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyActionButtonV2 from "@/components/TagbyActionButtonV2.vue"
import state from "../../state"
import getters from "../../getters"
import useCancelOrderSidebar from "../../useCancelOrderSidebar"

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      turnOnSidebar,
    } = useCancelOrderSidebar()

    return {
      state,
      getters,
      turnOnSidebar,
    }
  },
})
</script>
