<template>
  <div>
    <DeliveryCard
      v-for="delivery in state.deliveryList"
      :key="delivery.idx"
      :delivery="delivery"
    />
  </div>
</template>

<script>
import {
  defineComponent,
} from '@vue/composition-api'
import {
  BCard,
  BTable,
  BLink,
} from 'bootstrap-vue'
import DeliveryCard from './DeliveryCard.vue'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    DeliveryCard,
    BCard,
    BTable,
    BLink,
  },
  setup() {
    return {
      state,
      getters,
    }
  },
})
</script>
