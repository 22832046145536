import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getTimelineList,
    getDeliveryList,
  } = useFetch()

  const turnOnSidebar = delivery => {
    state.updateCustomsIdSidebar.isVisible = true
    state.updateCustomsIdSidebar.targetDelivery.idx = delivery.idx
    state.updateCustomsIdSidebar.targetDelivery.personal_customs_id = delivery.personal_customs_id
  }

  const turnOffSidebar = () => {
    state.updateCustomsIdSidebar.isVisible = false
  }

  const updateCustomeId = () => {
    state.updateCustomsIdSidebar.isSaving = true
    return api.updateCustomeId({
      order_group_idx: state.idx,
      delivery_idx: state.updateCustomsIdSidebar.targetDelivery.idx,
      personal_customs_id: state.updateCustomsIdSidebar.targetDelivery.personal_customs_id,
    }).then(() => {
      makeToast('primary', '개인통관번호 수정에 성공했습니다')
      getTimelineList()
      getDeliveryList()
      turnOffSidebar()
    }).catch(err => {
      makeToast('danger', '개인통관번호 수정에 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.updateCustomsIdSidebar.isSaving = false
    })
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    updateCustomeId,
  }
}
