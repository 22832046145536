import {
  ref,
  computed,
} from '@vue/composition-api'
import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getTimelineList,
    getDetail,
    getChargeList,
    getPayList,
  } = useFetch()

  const turnOnSidebar = () => {
    state.chargeAndPaySidebar.isVisible = true
  }

  const turnOffSidebar = () => {
    state.chargeAndPaySidebar.isVisible = false
  }

  const appliedAmount = computed(() => {
    if (state.chargeAndPaySidebar.isCancel) {
      return -state.chargeAndPaySidebar.amount
    }
    return state.chargeAndPaySidebar.amount
  })

  const isValid = computed(() => state.chargeAndPaySidebar.amount)

  const chargeAndPay = () => {
    state.chargeAndPaySidebar.isSaving = true
    return api.chargeAndPay({
      order_group_idx: state.idx,
      amount: appliedAmount.value,
      purpose: state.chargeAndPaySidebar.purpose,
    }).then(() => {
      makeToast('primary', '금액 조정에 성공했습니다')
      getTimelineList()
      getDetail()
      getChargeList()
      getPayList()
      turnOffSidebar()
    }).catch(err => {
      makeToast('danger', '금액 조정에 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.chargeAndPaySidebar.isSaving = false
    })
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    chargeAndPay,
    isValid,
  }
}
