<template>
  <div>
    <BCard no-body>
      <BTable
        :fields="chargeFields"
        :items="state.chargeList"
        responsive
      >
        <template #cell(amount)="data">
          {{ resolveMoney(data.value) }}
        </template>
      </BTable>
    </BCard>
  </div>
</template>

<script>
import {
  defineComponent,
} from '@vue/composition-api'
import {
  BCard,
  BTable,
  BLink,
} from 'bootstrap-vue'
import state from '../../state'
import getters from '../../getters'
import TagbySelect from '@/components/TagbySelect.vue'
import useResolveValue from '@/utils/resolveValue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BLink,
    TagbySelect,
  },
  setup() {
    const {
      resolveMoney,
    } = useResolveValue()

    const chargeFields = [
      { key: 'idx', label: 'idx' },
      { key: 'title', label: 'title' },
      { key: 'description', label: 'description' },
      { key: 'amount', label: 'amount' },
      { key: 'state', label: 'state' },
    ]

    return {
      state,
      getters,
      chargeFields,
      resolveMoney,
    }
  },
})
</script>
