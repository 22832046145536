import moment from "moment"
import useToast from "@/utils/toast"
import useReloveValue from "@/utils/resolveValue"
import router from "@/router"
import state from "./state"
import getters from "./getters"
import api from "./api"
import useErrorReason from "./useErroReason"

export default () => {
  const {
    makeToast,
  } = useToast()

  // const {
  //   resolveUtcDateTime,
  // } = useReloveValue()

  const {
    turnOnErrorReasonModal,
  } = useErrorReason()

  const getAdminMe = () => api.getAdminMe().then(response => {
    state.adminPermission = response.data.data.permission
  })

  const getDetail = () => api.getDetail({
    order_group_idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.orderGroup.idx = resData.idx
    state.orderGroup.order_no = resData.order_no
    state.orderGroup.state = resData.state
    state.orderGroup.amount = resData.amount
    state.orderGroup.shipping_fee = resData.shipping_fee
  }).catch(err => {
    state.hasLoadError = true
    turnOnErrorReasonModal(err)
  })

  const getOrderList = () => api.getOrderList({
    order_group_idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.orderList = resData
  }).catch(err => {
    state.hasLoadError = true
    turnOnErrorReasonModal(err)
  })

  const getDeliveryList = () => api.getDeliveryList({
    order_group_idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.deliveryList = resData
  }).catch(err => {
    turnOnErrorReasonModal(err)
  })

  const getOrderMember = () => api.getOrderMember({
    order_group_idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.orderMember.idx = resData.idx
    state.orderMember.type = resData.type
    state.orderMember.name = resData.name
    state.orderMember.phone = resData.phone
  }).catch(err => {
    turnOnErrorReasonModal(err)
  })

  const getMyshop = () => api.getMyshop({
    order_group_idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.orderStore.idx = resData?.idx
    state.orderStore.name = resData?.name
    state.orderStore.url_path = resData?.url_path
  }).catch(err => {
    turnOnErrorReasonModal(err)
  })

  const getChargeList = () => api.getChargeList({
    order_group_idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.chargeList = resData
  }).catch(err => {
    turnOnErrorReasonModal(err)
  })

  const getPayList = () => api.getPayList({
    order_group_idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.payList = resData
  }).catch(err => {
    turnOnErrorReasonModal(err)
  })

  const getTimelineList = () => api.getTimelineList({
    info_table: "order_group_info",
    info_idx: state.idx,
  }).then(response => {
    state.timelineList = response.data.data
  }).catch(err => {
    makeToast("danger", "타임라인을 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  return {
    getAdminMe,
    getDetail,
    getTimelineList,
    getOrderMember,
    getMyshop,
    getOrderList,
    getDeliveryList,
    getChargeList,
    getPayList,
  }
}
