import {
  computed,
  reactive,
} from '@vue/composition-api'

import state from './state'

const isCreatingMode = computed(() => !state.idx)
const orderGroupItems = computed(() => [state.orderGroup])
const orderMemberItems = computed(() => [state.orderMember])
const orderStoreItems = computed(() => [state.orderStore])

const isShpOrder = computed(() => state.orderMember.type === 'SHP')

export default reactive({
  isCreatingMode,
  orderGroupItems,
  orderMemberItems,
  orderStoreItems,
  isShpOrder,
})
