import {
  ref,
  computed,
} from '@vue/composition-api'
import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getTimelineList,
    getDetail,
    getOrderList,
    getChargeList,
    getPayList,
  } = useFetch()

  const turnOnSidebar = () => {
    state.cancelOrderSidebar.isVisible = true
  }

  const turnOffSidebar = () => {
    state.cancelOrderSidebar.isVisible = false
  }

  const editableState = [
    'ORDERED',
    'PAID',
    'READY',
  ]

  const cancelTableRef = ref()

  const orderNoList = computed(() => {
    const tableRef = cancelTableRef.value
    if (tableRef == null) {
      return []
    }
    const { selectedRows } = tableRef
    const selectedOrderList = state.orderList.filter((order, i) => selectedRows[i])
    return selectedOrderList.map(order => order.order_no)
  })

  const calculateCancelPrice = () => api.calculateCancelPrice({
    order_group_no: state.orderGroup.order_no,
    order_no_list: orderNoList.value,
  }).then(response => {
    const resData = response.data.data
    state.cancelOrderSidebar.cancelAmount = resData.total_amount + resData.total_vat
    state.cancelOrderSidebar.cancelShippingFee = resData.shipping_fee
  }).catch(err => {
    makeToast('danger', '취소 금액을 계산하는데 실패했습니다')
    turnOnErrorReasonModal(err)
  })

  const inputCheckbox = row => {
    const i = state.orderList.findIndex(order => order.idx === row.item.idx)
    if (editableState.includes(row.item.state) && !row.rowSelected) {
      cancelTableRef.value.selectRow(i)
    } else {
      cancelTableRef.value.unselectRow(i)
    }
  }

  const selectRow = items => {
    const disableItem = items.find(item => !editableState.includes(item.state))
    if (disableItem != null) {
      const i = state.orderList.findIndex(order => order.idx === disableItem.idx)
      cancelTableRef.value.unselectRow(i)
    }
    calculateCancelPrice()
  }

  const cancelOrder = () => {
    state.cancelOrderSidebar.isSaving = true
    return api.cancelOrder({
      order_group_no: state.orderGroup.order_no,
      order_no_list: orderNoList.value,
    }).then(() => {
      makeToast('primary', '주문 취소에 성공했습니다')
      getTimelineList()
      getDetail()
      getOrderList()
      getChargeList()
      getPayList()
      turnOffSidebar()
    }).catch(err => {
      makeToast('danger', '주문 취소에 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.cancelOrderSidebar.isSaving = false
    })
  }

  const isValid = computed(() => (orderNoList.value?.length ?? 0) > 0)

  return {
    turnOnSidebar,
    turnOffSidebar,
    cancelOrder,

    editableState,
    cancelTableRef,
    inputCheckbox,
    selectRow,

    isValid,
  }
}
