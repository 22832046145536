<template>
  <TagbySidebarWithButton
    :visible="state.chargeAndPaySidebar.isVisible"
    :isSaving="state.chargeAndPaySidebar.isSaving"
    :isValid="isValid"
    title="메모 생성"
    width="30%"
    @ok="chargeAndPay"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <TagbyInputRow label="목적">
      <BFormRadioGroup
        v-model="state.chargeAndPaySidebar.purpose"
        :options="purposeOptions"
      />
    </TagbyInputRow>

    <TagbyInputRow label="타입">
      <BFormRadioGroup
        v-model="state.chargeAndPaySidebar.isCancel"
        :options="isCancelOptions"
      />
    </TagbyInputRow>

    <TagbyInputRow label="결제수단">
      <BFormInput
        value="ACCOUNT"
        readonly
      />
    </TagbyInputRow>

    <TagbyInputRow label="금액">
      <TagbyMoneyInput
        v-model="state.chargeAndPaySidebar.amount"
        :class="state.chargeAndPaySidebar.isCancel ? 'text-danger' : 'text-success'"
      />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormTextarea,
  BFormRadioGroup,
  BFormInput,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import TagbyMoneyInput from '@/components/TagbyMoneyInput.vue'
import state from '../../state'
import useChargeAndPaySidebar from '../../useChargeAndPaySidebar'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    TagbySelect,
    TagbyMoneyInput,
    BFormTextarea,
    BFormRadioGroup,
    BFormInput,
  },
  setup() {
    const {
      turnOffSidebar,
      chargeAndPay,
      isValid,
    } = useChargeAndPaySidebar()

    const purposeOptions = [
      { text: '배송비', value: 'SHIPPING' },
      { text: '상품가격', value: 'ORDER' },
    ]

    const isCancelOptions = [
      { text: '추가', value: false },
      { text: '취소', value: true },
    ]

    return {
      state,
      turnOffSidebar,
      chargeAndPay,
      isValid,
      purposeOptions,
      isCancelOptions,
    }
  },
})
</script>
