<template>
  <TagbySidebarWithButton
    :visible="state.exchangeOrderSidebar.isVisible"
    :isSaving="state.exchangeOrderSidebar.isSaving"
    :isValid="isValid"
    title="주문 교환"
    width="30%"
    cancelText="닫기"
    @ok="exchangeOrder"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <BRow class="mb-1">
      <BCol>
        <h4>
          [{{ state.exchangeOrderSidebar.targetOrder.order_no }}] {{ state.exchangeOrderSidebar.targetOrder.product_name }}
        </h4>
        <div v-if="Boolean(targetOrderOptionText)">
          {{ targetOrderOptionText }}
        </div>
        <div v-else>
          (옵션없음)
        </div>
      </BCol>
    </BRow>

    <BRow>
      <BCol>
        <h5>
          가격: <span class="text-success">{{ resolveMoney(state.exchangeOrderSidebar.targetOrder.product_price) }}</span>
        </h5>
      </BCol>
    </BRow>

    <hr>

    <BRow>
      <BCol>
        <h4>
          교환상품 선택
        </h4>
      </BCol>
    </BRow>

    <BRow v-if="optionType === 'NO'">
      <BCol>
        -- 옵션없음 --
      </BCol>
    </BRow>

    <BRow v-else-if="optionType === 'SINGLE'">
      <BCol>
        <BFormGroup
          v-for="optionCategory in optionCategoryList"
          :key="optionCategory"
          :label="optionCategory"
        >
          <TagbySelect
            :options="getSingleOptionContentList(optionCategory)"
            :reduce="opt => opt.obj"
            @input="selectSingleContent"
          />
        </BFormGroup>
      </BCol>
    </BRow>

    <BRow v-else-if="optionType === 'COMBI'">
      <BCol>
        <BFormGroup
          v-for="(optionCategory, i) in optionCategoryList"
          :key="optionCategory"
          :label="optionCategory"
        >
          <TagbySelect
            :value="getCombiValue(optionCategory)"
            :options="getCombiOptionContentList(optionCategory)"
            :reduce="opt => opt.obj"
            @input="selectCombiContent($event, i+1)"
          />
        </BFormGroup>
      </BCol>
    </BRow>

    <BRow class="mt-1">
      <BCol>
        <h5>
          가격: <span class="text-warning">{{ resolveMoney(exchangedPrice) }}</span>
        </h5>
      </BCol>
    </BRow>

    {{ optionType }}
    {{ state.exchangeOrderSidebar.selectedContentByCategory }}

    <hr>

    <BTable
      :fields="exchangeFields"
      responsive
    >
      <template
        v-slot:custom-foot
      >
        <BTr class="tg-table-result">
          <BTd>{{ resolveMoney(state.exchangeOrderSidebar.targetOrder.product_price) }}</BTd>
          <BTd>{{ state.exchangeOrderSidebar.targetOrder.qty }}</BTd>
          <BTd
            class="text-right text-success"
          >
            {{ resolveMoney(state.exchangeOrderSidebar.targetOrder.amount) }}
          </BTd>
        </BTr>
        <BTr>
          <BTd>{{ resolveMoney(exchangedPrice) }}</BTd>
          <BTd>{{ state.exchangeOrderSidebar.targetOrder.qty }}</BTd>
          <BTd
            class="text-right text-danger"
          >
            {{ resolveMoney(exchangedAmount) }}
          </BTd>
        </BTr>
        <BTr>
          <BTd>고객부담 배송비</BTd>
          <BTd
            colspan="4"
          >
            <BFormGroup
              class="d-flex justify-content-end m-0"
            >
              <TagbyMoneyInput
                v-model="state.exchangeOrderSidebar.customerShippingFee"
                class="text-right text-danger"
                style="width: 200px"
              />
            </BFormGroup>
          </BTd>
        </BTr>
        <BTr class="tg-table-result">
          <BTd>결과</BTd>
          <BTd
            colspan="4"
            class="text-right"
            :class="resultAmount >= 0 ? 'text-success' : 'text-danger'"
          >
            {{ resolveMoney(resultAmount) }}
          </BTd>
        </BTr>
      </template>
    </BTable>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BTable,
  BFormCheckbox,
  BTr,
  BTd,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import state from '../../state'
import useExchangeOrderSidebar from '../../useExchangeOrderSidebar'
import useResolveValue from '@/utils/resolveValue'
import TagbySelect from '@/components/TagbySelect.vue'
import TagbyMoneyInput from '@/components/TagbyMoneyInput.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BTable,
    BFormCheckbox,
    BTr,
    BTd,
    BRow,
    BCol,
    BFormGroup,
    TagbySelect,
    TagbyMoneyInput,
  },
  setup() {
    const {
      turnOffSidebar,
      exchangeOrder,
      editableState,
      targetOrderList,
      targetOrderOptionText,
      optionCategoryList,
      optionType,
      getSingleOptionContentList,
      selectSingleContent,
      selectCombiContent,
      exchangedPrice,
      getCombiValue,
      getCombiOptionContentList,
      exchangedAmount,
      resultAmount,
      isValid,
    } = useExchangeOrderSidebar()

    const {
      resolveMoney,
    } = useResolveValue()

    const exchangeFields = [
      { key: 'price', label: '가격' },
      { key: 'qty', label: '수량' },
      { key: 'amount', label: '총액' },
    ]

    return {
      state,
      turnOffSidebar,
      exchangeOrder,
      resolveMoney,
      exchangeFields,
      editableState,
      targetOrderList,
      targetOrderOptionText,
      optionCategoryList,
      optionType,
      getSingleOptionContentList,
      selectSingleContent,
      selectCombiContent,
      exchangedPrice,
      getCombiValue,
      getCombiOptionContentList,
      exchangedAmount,
      resultAmount,
      isValid,
    }
  },
})
</script>

<style scoped>
.tg-table-result {
  border-top: 5px double;
}
</style>
