<template>
  <div>
    <BCard no-body>
      <BTable
        caption="주문그룹"
        caption-top
        responsive
        :fields="orderGroupFields"
        :items="getters.orderGroupItems"
      >
        <template #cell(order_no)="data">
          {{ data.value }}<TagbyCopyIcon :value="data.value" />
        </template>

        <template #cell(amount)="data">
          {{ resolveMoney(data.value) }}
        </template>

        <template #cell(shipping_fee)="data">
          {{ resolveMoney(data.value) }}
        </template>

        <template #cell(state)="data">
          {{ resolveState(data.value) }}
        </template>
      </BTable>
    </BCard>

    <BCard no-body>
      <BTable
        caption="구매자"
        caption-top
        responsive
        :fields="orderMemberFields"
        :items="getters.orderMemberItems"
      />
    </BCard>

    <BCard
      v-if="state.orderStore.idx != null"
      no-body
    >
      <BTable
        caption="마이스토어"
        caption-top
        responsive
        :fields="orderStoreFields"
        :items="getters.orderStoreItems"
      >
        <template #cell(url_path)="data">
          <BLink
            :href="getMystoreUrl(data.value)"
            target="_blank"
          >
            {{ getMystoreUrl(data.value) }}
          </BLink>
        </template>
      </BTable>
    </BCard>
  </div>
</template>

<script>
import {
  defineComponent,
} from '@vue/composition-api'
import {
  BCard,
  BTable,
  BLink,
} from 'bootstrap-vue'
import TagbyDatetimeInputV3 from '@/components/TagbyDatetimeInputV3.vue'
import state from '../../state'
import getters from '../../getters'
import TagbySelect from '@/components/TagbySelect.vue'
import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'
import useResolveValue from '@/utils/resolveValue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BLink,
    TagbyDatetimeInputV3,
    TagbySelect,
    TagbyCopyIcon,
  },
  setup() {
    const {
      resolveMoney,
    } = useResolveValue()

    const orderGroupFields = [
      { key: 'idx', label: 'idx' },
      { key: 'order_no', label: 'order_group_no' },
      { key: 'amount', label: 'amount' },
      { key: 'shipping_fee', label: 'shipping_fee' },
      { key: 'state', label: 'state' },
    ]

    const orderMemberFields = [
      { key: 'idx', label: 'idx' },
      { key: 'type', label: 'type' },
      { key: 'name', label: 'name' },
      { key: 'phone', label: 'phone' },
    ]

    const orderStoreFields = [
      { key: 'idx', label: 'idx' },
      { key: 'name', label: 'name' },
      { key: 'url_path', label: 'url_path' },
    ]

    const getMystoreUrl = urlPath => `${process.env.VUE_APP_MYSTORE_URL}/${urlPath}`

    const resolveState = value => {
      if (value === 'REGISTER') {
        return '결제이탈'
      }
      if (value === 'ORDERED') {
        return '주문완료'
      }
      if (value === 'PAID') {
        return '결제완료'
      }
      if (value === 'CANCEL') {
        return '취소'
      }
      if (value === 'WITHDRAW') {
        return '삭제'
      }
      return '알수없음'
    }

    return {
      state,
      getters,
      orderGroupFields,
      orderMemberFields,
      orderStoreFields,
      getMystoreUrl,
      resolveMoney,
      resolveState,
    }
  },
})
</script>
