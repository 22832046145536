import {
  ref,
  computed,
} from "@vue/composition-api"
import state from "./state"
import api from "./api"
import useToast from "@/utils/toast"
import useErroReason from "./useErroReason"
import useFetch from "./useFetch"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getTimelineList,
    getDetail,
    getOrderList,
    getChargeList,
    getPayList,
    getDeliveryList,
  } = useFetch()

  const turnOnSidebar = () => {
    state.takebackOrderSidebar.isVisible = true
  }

  const turnOffSidebar = () => {
    state.takebackOrderSidebar.isVisible = false
  }

  const editableState = [
    "PAID",
    "READY",
    "DELIVERY",
    "DONE",
  ]

  const takebackTableRef = ref()

  const orderNoList = computed(() => {
    const tableRef = takebackTableRef.value
    if (tableRef == null) {
      return []
    }
    const { selectedRows } = tableRef
    const selectedOrderList = state.orderList.filter((order, i) => selectedRows[i])
    return selectedOrderList.map(order => order.order_no)
  })

  const calculateCancelPrice = () => api.calculateTakebackPrice({
    order_group_no: state.orderGroup.order_no,
    order_no_list: orderNoList.value,
    cause_customer: state.takebackOrderSidebar.causeCustomer,
  }).then(response => {
    const resData = response.data.data
    state.takebackOrderSidebar.cancelAmount = resData.total_amount + resData.total_vat
    state.takebackOrderSidebar.cancelShippingFee = resData.shipping_fee
  }).catch(err => {
    makeToast("danger", "취소 금액을 계산하는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  const inputCheckbox = row => {
    const i = state.orderList.findIndex(order => order.idx === row.item.idx)
    if (editableState.includes(row.item.state) && !row.rowSelected) {
      takebackTableRef.value.selectRow(i)
    } else {
      takebackTableRef.value.unselectRow(i)
    }
  }

  const selectRow = items => {
    const disableItem = items.find(item => !editableState.includes(item.state))
    if (disableItem != null) {
      const i = state.orderList.findIndex(order => order.idx === disableItem.idx)
      takebackTableRef.value.unselectRow(i)
    }
    calculateCancelPrice()
  }

  const takebackAmount = computed(() => state.takebackOrderSidebar.cancelAmount + state.takebackOrderSidebar.cancelShippingFee - state.takebackOrderSidebar.customerShippingFee)
  const isValidAmount = computed(() => state.takebackOrderSidebar.useBankingCancelShippingFee || takebackAmount.value >= 0)

  const takebackOrder = () => {
    state.takebackOrderSidebar.isSaving = true
    return api.takebackOrder({
      order_group_no: state.orderGroup.order_no,
      order_no_list: orderNoList.value,
      cause_customer: state.takebackOrderSidebar.causeCustomer,
      cancel_shipping_fee: state.takebackOrderSidebar.customerShippingFee,
      use_banking_cancel_shipping_fee: state.takebackOrderSidebar.useBankingCancelShippingFee,
    }).then(() => {
      makeToast("primary", "주문 환불에 성공했습니다")
      getTimelineList()
      getDetail()
      getOrderList()
      getChargeList()
      getPayList()
      getDeliveryList()
      turnOffSidebar()
    }).catch(err => {
      makeToast("danger", "주문 환불에 실패했습니다")
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.takebackOrderSidebar.isSaving = false
    })
  }

  const isValid = computed(() => (orderNoList.value?.length ?? 0) > 0)

  return {
    turnOnSidebar,
    turnOffSidebar,
    takebackOrder,

    editableState,
    takebackTableRef,
    inputCheckbox,
    selectRow,
    calculateCancelPrice,

    takebackAmount,
    isValidAmount,

    isValid,
  }
}
