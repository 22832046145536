<template>
  <TagbySidebarWithButton
    :visible="state.updateTrackingSidebar.isVisible"
    :isSaving="state.updateTrackingSidebar.isSaving"
    :isValid="true"
    title="송장 변경"
    width="30%"
    @ok="updateTracking"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <TagbyInputRow label="배송사">
      <TagbySelect
        v-model="state.updateTrackingSidebar.targetDelivery.shipment_company"
        :options="shipmentCompanyOptions"
      />
    </TagbyInputRow>

    <TagbyInputRow label="송장번호">
      <BFormInput v-model="state.updateTrackingSidebar.targetDelivery.tracking_no" />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import state from '../../state'
import useUpdateTracking from '../../useUpdateTracking'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    TagbySelect,
    BFormInput,
    BFormTextarea,
  },
  setup() {
    const {
      turnOffSidebar,
      updateTracking,
    } = useUpdateTracking()

    const shipmentCompanyOptions = [
      '일양로지스',
      'EMS',
      'DHL',
      'UPS',
      '한의사랑택배',
      '천일택배',
      '건영택배',
      '한덱스',
      'FedEx',
      '대신택배',
      '경동택배',
      'GS Postbox 택배',
      'TNT Express',
      'USPS',
      'GSMNtoN',
      'KGL네트웍스',
      '합동택배',
      'DHL Global Mail',
      'i-Parcel',
      'LX판토스',
      'ECMS Express',
      '굿투럭',
      'GSI Express',
      'CJ대한통운 국제특송',
      '애니트랙',
      'SLX택배',
      '호남택배',
      'CU 편의점택배',
      '우리한방택배',
      'ACI Express',
      'A.C.E EXPRESS INC',
      'GPS Logix',
      '성원글로벌카고',
      '농협택배',
      '홈픽택배',
      'EuroParcel',
      'Cway Express',
      'YJS글로벌(영국)',
      '은하쉬핑',
      'YJS글로벌(월드)',
      'Giant Network Group',
      '디디로지스',
      '대림통운',
      'LOTOS CORPORATION',
      'IK물류',
      '성훈물류',
      'CR로지텍',
      '용마로지스',
      '원더스퀵',
      'LineExpress',
      '로지스밸리택배',
      '제니엘시스템',
      '컬리로지스',
      '스마트로지스',
      '풀앳홈',
      '삼성전자물류',
      '이투마스(ETOMARS)',
      '큐런택배',
      '두발히어로',
      '위니아딤채',
      '하이브시티',
      '지니고 당일배송',
      '팬스타국제특송(PIEX)',
      '오늘의픽업',
      '큐익스프레스',
      '로지스밸리',
      '에이씨티앤코아물류',
      '롯데택배 해외특송',
      '나은물류',
      '한샘서비스원 택배',
      '배송하기좋은날(SHIPNERGY)',
      'NDEX KOREA',
      '도도플렉스(dodoflex)',
      'BRIDGE LOGIS',
      '허브넷로지스틱스',
      'LG전자(판토스)',
      'MEXGLOBAL',
      '윈핸드해운항공',
      '부릉',
      'SBGLS',
      '1004홈',
      '썬더히어로',
      '캐나다쉬핑',
      '(주)팀프레시',
      'YUNDA EXPRESS',
      '롯데칠성',
      '핑퐁',
      '발렉스 특수물류',
      '바바바(bababa)',
      'BAIMA EXPRESS',
      '엔티엘피스',
      'LTL',
      'GTS로지스',
      '㈜올타코리아',
      '로지스팟',
      '판월드로지스틱㈜',
      '홈픽 오늘도착',
      '로지스파트너',
      '딜리래빗',
      '지오피',
      '우체국택배',
      'CJ대한통운',
      '한진택배',
      '로젠택배',
      '롯데택배',
    ]

    return {
      state,
      shipmentCompanyOptions,
      turnOffSidebar,
      updateTracking,
    }
  },
})
</script>
