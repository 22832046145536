import {
  ref,
  computed,
} from '@vue/composition-api'
import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'
import useResolveValue from '@/utils/resolveValue'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    resolveMoney
  } = useResolveValue()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getTimelineList,
    getDetail,
    getOrderList,
    getChargeList,
    getPayList,
    getDeliveryList,
  } = useFetch()

  const targetOrderList = computed(() => [state.exchangeOrderSidebar.targetOrder])
  const optionType = computed(() => {
    if (!state.exchangeOrderSidebar.optionList?.length) {
      return 'NO'
    }
    const optionHadParent = state.exchangeOrderSidebar.optionList.find(o => Boolean(o.parent_idx))
    if (optionHadParent != null) {
      return 'COMBI'
    }
    return 'SINGLE'
  })
  const targetOrderOptionText = computed(() => {
    const categoryAndName = state.exchangeOrderSidebar.targetOrder.options?.map(o => `${o.category}:${o.content}`)
    return categoryAndName?.join(' / ')
  })
  const optionCategoryList = computed(() => {
    const categoryList = state.exchangeOrderSidebar.optionList.map(o => o.category)
    return categoryList.reduce((p, c) => {
      if (!p.includes(c)) {
        return [...p, c]
      }
      return p
    }, [])
  })

  const exchangedPrice = computed(() => {
    const productPrice = state.exchangeOrderSidebar.product.price
    const selectedList = Object.values(state.exchangeOrderSidebar.selectedContentByCategory)
    const addedPrice = selectedList.reduce((p, c) => {
      if (c.add_price == null) {
        return p
      }
      return p + c.add_price
    }, 0) ?? 0
    return productPrice + addedPrice
  })

  const exchangedAmount = computed(() => exchangedPrice.value * state.exchangeOrderSidebar.targetOrder.qty)

  const resultAmount = computed(() => state.exchangeOrderSidebar.targetOrder.amount - exchangedAmount.value - state.exchangeOrderSidebar.customerShippingFee)

  const getSingleOptionContentList = category => {
    const filteredOptionList = state.exchangeOrderSidebar.optionList.filter(o => o.category === category)
    return filteredOptionList.map(o => ({
      label: `${o.name} ( + ${resolveMoney(o.add_price)}원 )`,
      value: o.name,
      obj: o,
    }))
  }

  const getCombiOptionContentList = category => {
    const index = optionCategoryList.value.findIndex(c => c === category)
    if (index === 0) {
      const filteredOptionList = state.exchangeOrderSidebar.optionList.filter(o => o.category === category)
      return filteredOptionList.map(o => ({
        label: `${o.name} ( + ${resolveMoney(o.add_price)}원 )`,
        value: o.name,
        obj: o,
      }))
    }
    const categoryList = optionCategoryList.value.slice(0, index)
    const isReady = categoryList.reduce((p, c) => {
      const selectedInfo = state.exchangeOrderSidebar.selectedContentByCategory[c]
      return p && (selectedInfo.name != null)
    }, true)
    if (!isReady) {
      return []
    }
    const lastCategory = categoryList[index - 1]
    const lastSelectedInfo = state.exchangeOrderSidebar.selectedContentByCategory[lastCategory]
    const lastParentIdx = lastSelectedInfo.idx
    const filteredOptionList = state.exchangeOrderSidebar.optionList.filter(o => o.parent_idx === lastParentIdx)
    return filteredOptionList.map(o => ({
      label: `${o.name} ( + ${resolveMoney(o.add_price)}원 )`,
      value: o.name,
      obj: o,
    }))
  }

  const getProduct = () => api.getProduct({
    product_idx: state.exchangeOrderSidebar.targetOrder.product_idx,
  }).then(response => {
    const resData = response.data.data
    state.exchangeOrderSidebar.product.idx = resData.idx
    state.exchangeOrderSidebar.product.name = resData.name
    state.exchangeOrderSidebar.product.price = resData.price
  }).catch(err => {
    makeToast('danger', '상품 정보를 가져오는데 실패했습니다')
    turnOnErrorReasonModal(err)
  })

  const setSelectedContent = (from = 0) => {
    const slicedCategoryList = optionCategoryList.value.slice(from)
    const reducedCategoryList = slicedCategoryList.reduce((p, c) => ({
      ...p,
      [c]: {
        idx: null,
        name: null,
        add_price: null,
      },
    }), {})
    state.exchangeOrderSidebar.selectedContentByCategory = {
      ...state.exchangeOrderSidebar.selectedContentByCategory,
      ...reducedCategoryList,
    }
  }

  const getCombiValue = category => state.exchangeOrderSidebar.selectedContentByCategory[category].name

  const selectSingleContent = option => {
    const selectedContent = state.exchangeOrderSidebar.selectedContentByCategory[option.category]
    selectedContent.idx = option.idx
    selectedContent.name = option.name
    selectedContent.add_price = option.add_price
  }

  const selectCombiContent = (option, i) => {
    selectSingleContent(option)
    setSelectedContent(i)
  }

  const getProductOptionList = () => api.getProductOptionList({
    product_idx: state.exchangeOrderSidebar.targetOrder.product_idx,
  }).then(response => {
    const resData = response.data.data
    state.exchangeOrderSidebar.optionList = resData
    setSelectedContent()
  }).catch(err => {
    makeToast('danger', '상품 옵션 정보를 가져오는데 실패했습니다')
    turnOnErrorReasonModal(err)
  })

  const turnOnSidebar = order => {
    state.exchangeOrderSidebar.isVisible = true
    state.exchangeOrderSidebar.targetOrder.idx = order.idx
    state.exchangeOrderSidebar.targetOrder.product_idx = order.product_idx
    state.exchangeOrderSidebar.targetOrder.product_name = order.product_name
    state.exchangeOrderSidebar.targetOrder.product_price = order.product_price
    state.exchangeOrderSidebar.targetOrder.order_no = order.order_no
    state.exchangeOrderSidebar.targetOrder.options = order.options
    state.exchangeOrderSidebar.targetOrder.amount = order.amount
    state.exchangeOrderSidebar.targetOrder.qty = order.qty
    state.exchangeOrderSidebar.optionList = []
    state.exchangeOrderSidebar.selectedContentByCategory = {}
    state.exchangeOrderSidebar.exchangedPrice = null
    state.exchangeOrderSidebar.customerShippingFee = null
    getProduct()
    getProductOptionList()
  }

  const turnOffSidebar = () => {
    state.exchangeOrderSidebar.isVisible = false
  }

  const editableState = [
    'ORDERED',
    'PAID',
    'READY',
    'DELIVERY',
    'DONE',
  ]

  const selectedOptionIdxList = computed(() => {
    const selectedOptionList = Object.values(state.exchangeOrderSidebar.selectedContentByCategory)
    const optionIdxList = selectedOptionList.map(o => o.idx)
    if (optionType.value === 'SINGLE') {
      return optionIdxList
    }
    if (optionType.value === 'COMBI') {
      const lastIndex = optionIdxList.length - 1
      return [optionIdxList[lastIndex]]
    }
    return []
  })

  const exchangeOrder = () => {
    state.exchangeOrderSidebar.isSaving = true
    return api.exchangeOrder({
      order_group_no: state.orderGroup.order_no,
      order_no: state.exchangeOrderSidebar.targetOrder.order_no,
      option_idx_list: selectedOptionIdxList.value,
      exchange_shipping_fee: state.exchangeOrderSidebar.customerShippingFee,
    }).then(() => {
      makeToast('primary', '주문 교환에 성공했습니다')
      getTimelineList()
      getDetail()
      getOrderList()
      getChargeList()
      getPayList()
      getDeliveryList()
      turnOffSidebar()
    }).catch(err => {
      makeToast('danger', '주문 교환에 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.exchangeOrderSidebar.isSaving = false
    })
  }

  // const isValid = computed(() => (selectedOptionIdxList.value?.length ?? 0) > 0)
  const isValid = computed(() => {
    const i = selectedOptionIdxList.value
    console.log(i)
    // return i.length > 0
    return i.reduce((p, c) => p && (c != null), true)
  })

  return {
    turnOnSidebar,
    turnOffSidebar,
    exchangeOrder,

    editableState,

    getProduct,
    getProductOptionList,
    targetOrderList,
    targetOrderOptionText,
    optionCategoryList,
    optionType,
    getSingleOptionContentList,
    selectSingleContent,
    selectCombiContent,
    exchangedPrice,
    getCombiValue,
    getCombiOptionContentList,
    exchangedAmount,
    resultAmount,

    isValid,
  }
}
