<template>
  <TagbyPrimaryModal
    :visible="state.resetTrackingModal.isVisible"
    title="송장 초기화"
    content="송장을 초기화 하시겠습니까?"
    @ok="resetTracking"
    @hidden="turnOffModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import useResetTracking from '../../useResetTracking'
import state from '../../state'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    const {
      resetTracking,
      turnOffModal,
    } = useResetTracking()

    return {
      state,
      resetTracking,
      turnOffModal,
    }
  },
})
</script>
