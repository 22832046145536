<template>
  <BCard>
    <BRow>
      <BCol class="d-flex justify-content-between">
        <span class="tg-card-header">
          주문번호 {{ delivery.order_no }} <TagbyCopyIcon :value="delivery.order_no" />
        </span>
        <BDropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="text-body"
            />
          </template>
          <BDropdownItem
            href="#"
            @click="turnOnUpdateAddressSidebar(delivery)"
          >
            주소 수정
          </BDropdownItem>
          <BDropdownItem
            href="#"
            @click="turnOnUpdateTrackingSidebar(delivery)"
          >
            송장 수정
          </BDropdownItem>
          <BDropdownItem
            href="#"
            @click="turnOnResetTrackingModal(delivery)"
          >
            송장 초기화
          </BDropdownItem>
          <BDropdownItem
            href="#"
            @click="turnOnUpdateCustomsIdSidebar(delivery)"
          >
            개인통관번호 수정
          </BDropdownItem>
        </BDropdown>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <DataRow
          label="idx"
          :value="delivery.idx"
        />
        <div>
          <span class="text-muted">
            상태:
          </span>
          <span>
            <BBadge :variant="deliveryStateColor">
              {{ resolveState(delivery.state) }}
            </BBadge>
          </span>
        </div>
        <DataRow
          v-if="delivery.personal_customs_id != null"
          label="개인통관 고유번호"
          :value="delivery.personal_customs_id"
          copiable
        />
        <hr>
        <DataRow
          label="주소"
          :value="deliveryAddress"
          copiable
        />
        <DataRow
          label="우편번호"
          :value="delivery.zipcode"
        />
        <DataRow
          label="메세지"
          :value="delivery.message"
          copiable
        />
        <DataRow
          label="이름"
          :value="delivery.recipient_name"
        />
        <DataRow
          label="연락처"
          :value="delivery.phone"
        />
        <hr>
        <DataRow
          label="배송사"
          :value="delivery.shipment_company"
        />
        <DataRow
          label="송장번호"
          :value="delivery.tracking_no"
          copiable
        />
        <hr>
        <DataRow
          label="송장입력일"
          :value="resolveDateTime(delivery.registered_at)"
        />
        <DataRow
          label="출고일"
          :value="resolveDateTime(delivery.start_at)"
        />
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import {
  defineComponent,
  computed,
} from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BBadge,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import DataRow from './DataRow.vue'
import useResolveValue from '@/utils/resolveValue'
import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'
import useUpdateAddress from '../../useUpdateAddress'
import useUpdateTracking from '../../useUpdateTracking'
import useResetTracking from '../../useResetTracking'
import useUpdateCustomsId from '../../useUpdateCustomsId'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    DataRow,
    BDropdown,
    BDropdownItem,
    TagbyCopyIcon,
  },
  props: {
    delivery: {
      type: Object,
    },
  },
  setup(props) {
    const {
      resolveDateTime,
    } = useResolveValue()

    const {
      turnOnSidebar: turnOnUpdateAddressSidebar,
    } = useUpdateAddress()

    const {
      turnOnSidebar: turnOnUpdateTrackingSidebar,
    } = useUpdateTracking()

    const {
      turnOnModal: turnOnResetTrackingModal,
    } = useResetTracking()
    const {
      turnOnSidebar: turnOnUpdateCustomsIdSidebar,
    } = useUpdateCustomsId()

    const deliveryStateColor = computed(() => {
      if (['READY'].includes(props.delivery.state)) {
        return 'primary'
      }

      if (['DELIVERY'].includes(props.delivery.state)) {
        return 'info'
      }

      return 'secondary'
    })

    const deliveryAddress = computed(() => {
      if (!props.delivery.address_detail) {
        return props.delivery.address
      }
      return `${props.delivery.address} ${props.delivery.address_detail}`
    })

    const resolveState = value => {
      if (value === 'WAIT') {
        return '주문확인중'
      }
      if (value === 'READY') {
        return '상품준비중'
      }
      if (value === 'DELIVERY') {
        return '배송중'
      }
      if (value === 'DONE') {
        return '배송완료'
      }
      if (value === 'WITHDRAW') {
        return '삭제'
      }
      return '알수없음'
    }

    return {
      deliveryStateColor,
      deliveryAddress,
      resolveDateTime,
      turnOnUpdateAddressSidebar,
      turnOnUpdateTrackingSidebar,
      turnOnResetTrackingModal,
      turnOnUpdateCustomsIdSidebar,
      resolveState,
    }
  },
})
</script>

<style scoped>
.tg-card-header {
  font-size: 1.2rem;
  font-weight: bold;
}
</style>
