<template>
  <TagbyPrimaryModal
    :visible="state.revertCanceledOrderGroupModal.isVisible"
    title="취소주문 복구"
    content="취소된 주문이 결제대기 상태로 복구됩니다. 결제가 완료되었다면 결제완료 버튼을 눌러 결제완료처리까지 해주세요"
    @ok="revertCanceledOrderGroup"
    @hidden="turnOffModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import useRevertCanceledOrderGroup from '../../useRevertCanceledOrderGroup'
import state from '../../state'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    const {
      revertCanceledOrderGroup,
      turnOffModal,
    } = useRevertCanceledOrderGroup()

    return {
      state,
      revertCanceledOrderGroup,
      turnOffModal,
    }
  },
})
</script>
