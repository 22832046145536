<template>
  <TagbyActionButtonV2
    v-if="getters.isShpOrder"
    :isValid="true"
    variant="outline-secondary"
    text="취소주문 복구"
    @click="turnOnModal"
  />
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyActionButtonV2 from "@/components/TagbyActionButtonV2.vue"
import state from "../../state"
import getters from "../../getters"
import useRevertCanceledOrderGroup from "../../useRevertCanceledOrderGroup"

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      turnOnModal,
    } = useRevertCanceledOrderGroup()

    return {
      state,
      getters,
      turnOnModal,
    }
  },
})
</script>
