import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getTimelineList,
    getOrderMember,
  } = useFetch()

  const turnOnSidebar = () => {
    state.updateShpSidebar.isVisible = true
    state.updateShpSidebar.name = state.orderMember.name
    state.updateShpSidebar.phone = state.orderMember.phone
  }

  const turnOffSidebar = () => {
    state.updateShpSidebar.isVisible = false
  }

  const updateShp = () => {
    state.updateShpSidebar.isSaving = true
    return api.updateShp({
      order_group_idx: state.idx,
      shp_idx: state.orderMember.idx,
      name: state.updateShpSidebar.name,
      phone: state.updateShpSidebar.phone,
    }).then(() => {
      makeToast('primary', '구매자 변경에 성공했습니다')
      getTimelineList()
      getOrderMember()
      turnOffSidebar()
    }).catch(err => {
      makeToast('danger', '구매자 변경에 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.updateShpSidebar.isSaving = false
    })
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    updateShp,
  }
}
