<template>
  <BCard>
    <BRow>
      <BCol class="d-flex justify-content-between">
        <span class="tg-card-header">
          주문번호 {{ order.order_no }} <TagbyCopyIcon :value="order.order_no" />
        </span>
        <BDropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="text-body"
            />
          </template>
          <BDropdownItem
            href="#"
            :disabled="!editableStateWhenExchange.includes(order.state)"
            @click="turnOnExchangeOrderSidebar(order)"
          >
            교환
          </BDropdownItem>
        </BDropdown>
      </BCol>
    </BRow>
    <BRow>
      <BCol
        md="2"
        sm="4"
        cols="6"
      >
        <BImg
          :src="order.product_thumb_img"
          fluid
          rounded
        />
      </BCol>
      <BCol
        md="5"
        sm="8"
        cols="6"
      >
        <DataRow
          label="idx"
          :value="order.idx"
        />

        <div>
          <span class="text-muted">
            상태:
          </span>
          <span>
            <BBadge :variant="orderStateColor">
              {{ resolveState(order.state) }}
            </BBadge>
          </span>
        </div>

        <DataRow
          label="주문번호"
          :value="order.order_no"
          copiable
        />

        <DataRow
          label="상품명"
          :value="order.product_name"
        />

        <div>
          <span class="text-muted">
            태그바이 상품번호:
          </span>
          <span>
            <BLink :to="{name: 'commerce-product-detail', params: {idx: order.product_idx}}">
              {{ order.tagby_product_no }}
            </BLink>
            <TagbyCopyIcon :value="order.order_no" />
          </span>
        </div>

        <div>
          <span class="text-muted">
            벤더:
          </span>
          <span>
            {{ order.company_name }} ( {{ order.vendor_idx }}
            <span v-if="order.legacy_vendor_no != null">/ {{ order.legacy_vendor_no }}</span> )
          </span>
        </div>

        <DataRow
          label="가격"
          :value="resolveMoney(order.product_price)"
        />

        <DataRow
          label="수량"
          :value="order.qty"
        />

        <DataRow
          label="금액"
          :value="resolveMoney(order.amount)"
        />

        <DataRow
          label="주문일시"
          :value="resolveDateTime(order.created_at)"
        />
      </BCol>

      <BCol
        v-if="order.options != null && order.options.length > 0"
        md="5"
        cols="12"
      >
        <BTable
          :fields="optionFields"
          :items="order.options"
          small
        />
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import {
  defineComponent,
  computed,
} from "@vue/composition-api"
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BTable,
  BBadge,
  BDropdown,
  BDropdownItem,
  BLink,
} from "bootstrap-vue"
import TagbyCopyIcon from "@/components/TagbyCopyIcon.vue"
import DataRow from "./DataRow.vue"
import useResolveValue from "@/utils/resolveValue"
import useExchangeOrderSidebar from "../../useExchangeOrderSidebar"

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BLink,
    DataRow,
    TagbyCopyIcon,
  },
  props: {
    order: {
      type: Object,
    },
  },
  setup(props) {
    const {
      resolveDateTime,
      resolveMoney,
    } = useResolveValue()

    const {
      editableState: editableStateWhenExchange,
      turnOnSidebar: turnOnExchangeOrderSidebar,
    } = useExchangeOrderSidebar()

    const optionFields = [
      { key: "category", label: "구성" },
      { key: "content", label: "이름" },
      { key: "add_price", label: "추가금액" },
    ]

    const orderStateColor = computed(() => {
      if (["REGISTER", "PAID", "DONE"].includes(props.order.state)) {
        return "secondary"
      }

      if (["ORDERED"].includes(props.order.state)) {
        return "warning"
      }

      if (["READY"].includes(props.order.state)) {
        return "primary"
      }

      if (["DELIVERY"].includes(props.order.state)) {
        return "info"
      }

      return "danger"
    })

    const resolveState = value => {
      if (value === "REGISTER") {
        return "결제이탈"
      }
      if (value === "ORDERED") {
        return "주문완료"
      }
      if (value === "PAID") {
        return "결제완료"
      }
      if (value === "READY") {
        return "배송준비"
      }
      if (value === "DELIVERY") {
        return "배송중"
      }
      if (value === "DONE") {
        return "배송완료"
      }
      if (value === "CANCELING") {
        return "취소중"
      }
      if (value === "CANCELED") {
        return "취소완료"
      }
      if (value === "TAKEBACK") {
        return "환불중"
      }
      if (value === "TAKEBACKED") {
        return "환불완료"
      }
      if (value === "EXCHANGE") {
        return "교환중"
      }
      if (value === "EXCHANGED") {
        return "교환완료"
      }
      if (value === "WITHDRAW") {
        return "삭제"
      }
      return "알수없음"
    }

    return {
      resolveDateTime,
      resolveMoney,
      optionFields,
      orderStateColor,
      turnOnExchangeOrderSidebar,
      editableStateWhenExchange,
      resolveState,
    }
  },
})
</script>

<style scoped>
.tg-content {
  font-size: 1.2rem;
}
.tg-card-header {
  font-size: 1.2rem;
  font-weight: bold;
}
</style>
