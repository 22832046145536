<template>
  <div>
    <OrderCard
      v-for="order in state.orderList"
      :key="order.idx"
      :order="order"
    />
  </div>
</template>

<script>
import {
  defineComponent,
} from '@vue/composition-api'
import {
  BCard,
  BTable,
  BLink,
} from 'bootstrap-vue'
import state from '../../state'
import getters from '../../getters'
import OrderCard from './OrderCard.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BLink,
    OrderCard,
  },
  setup() {
    return {
      state,
      getters,
    }
  },
})
</script>
