<template>
  <TagbySidebarWithButton
    :visible="state.updateShpSidebar.isVisible"
    :isSaving="state.updateShpSidebar.isSaving"
    :isValid="true"
    title="구매자 수정"
    width="30%"
    @ok="updateShp"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <TagbyInputRow label="이름">
      <BFormInput v-model="state.updateShpSidebar.name" />
    </TagbyInputRow>

    <TagbyInputRow label="연락처">
      <BFormInput v-model="state.updateShpSidebar.phone" />
    </TagbyInputRow>

  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import state from '../../state'
import useUpdateShp from '../../useUpdateShp'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormInput,
    BFormTextarea,
  },
  setup() {
    const {
      turnOffSidebar,
      updateShp,
    } = useUpdateShp()

    return {
      state,
      turnOffSidebar,
      updateShp,
    }
  },
})
</script>
