<template>
  <TagbyPrimaryModal
    :visible="state.setPaidModal.isVisible"
    title="결제완료"
    content="같은 주문그룹의 결제대기 상태인 모든 주문이 결제완료로 변경됩니다. 변경하시겠습니까?"
    @ok="setPaid"
    @hidden="turnOffModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import useSetPaid from '../../useSetPaid'
import state from '../../state'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    const {
      setPaid,
      turnOffModal,
    } = useSetPaid()

    return {
      state,
      setPaid,
      turnOffModal,
    }
  },
})
</script>
