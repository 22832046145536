<template>
  <TagbySidebarWithButton
    :visible="state.takebackOrderSidebar.isVisible"
    :isSaving="state.takebackOrderSidebar.isSaving"
    :isValid="isValid"
    title="주문 환불"
    width="50%"
    cancelText="닫기"
    @ok="takebackOrder"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <TagbyInputRow label="고객의 귀책 사유입니까?">
      <BFormRadioGroup
        v-model="state.takebackOrderSidebar.causeCustomer"
        :options="causeOptions"
        @input="calculateCancelPrice"
      />
    </TagbyInputRow>
    <TagbyInputRow label="반송 배송비를 계좌이체로 받습니까?">
      <BFormRadioGroup
        v-model="state.takebackOrderSidebar.useBankingCancelShippingFee"
        :options="useBankingOptions"
      />
    </TagbyInputRow>
    <BTable
      ref="takebackTableRef"
      :fields="orderFields"
      :items="state.orderList"
      selectable
      responsive
      select-mode="multi"
      @row-selected="selectRow"
    >
      <template #cell(selected)="data">
        <BFormCheckbox
          :checked="data.rowSelected"
          :disabled="!editableState.includes(data.item.state)"
          @change="inputCheckbox(data)"
        />
      </template>

      <template #cell(amount)="data">
        {{ resolveMoney(data.value) }}
      </template>

      <template
        v-slot:custom-foot
      >
        <BTr class="tg-table-result">
          <BTd>상품총액</BTd>
          <BTd
            colspan="4"
            class="text-right text-success"
          >
            {{ resolveMoney(state.takebackOrderSidebar.cancelAmount) }}
          </BTd>
        </BTr>
        <BTr>
          <BTd>배송비</BTd>
          <BTd
            colspan="4"
            class="text-right"
            :class="state.takebackOrderSidebar.cancelShippingFee >= 0 ? 'text-success': 'text-danger'"
          >
            {{ resolveMoney(state.takebackOrderSidebar.cancelShippingFee) }}
          </BTd>
        </BTr>
        <BTr>
          <BTd>반송 배송비</BTd>
          <BTd
            colspan="4"
          >
            <BFormGroup
              class="d-flex justify-content-end m-0"
              :state="isValidAmount"
              invalid-feedback="환불가능총액보다 큰 값을 요구할 수 없습니다"
            >
              <TagbyMoneyInput
                v-model="state.takebackOrderSidebar.customerShippingFee"
                class="text-right text-danger"
                style="width: 200px"
              />
            </BFormGroup>
          </BTd>
        </BTr>
        <BTr class="tg-table-result">
          <BTd>취소총액</BTd>
          <BTd
            colspan="4"
            class="text-right"
          >
            {{ resolveMoney(takebackAmount) }}
          </BTd>
        </BTr>
      </template>
    </BTable>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BTable,
  BFormCheckbox,
  BTr,
  BTd,
  BFormRadioGroup,
  BFormGroup,
} from "bootstrap-vue"
import TagbySidebarWithButton from "@/components/TagbySidebarWithButton.vue"
import TagbyInputRow from "@/components/TagbyInputRow.vue"
import state from "../../state"
import useTakebackOrderSidebar from "../../useTakebackOrderSidebar"
import useResolveValue from "@/utils/resolveValue"
import TagbyMoneyInput from "@/components/TagbyMoneyInput.vue"

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BTable,
    BFormCheckbox,
    BTr,
    BTd,
    BFormRadioGroup,
    BFormGroup,
    TagbyMoneyInput,
  },
  setup() {
    const {
      turnOffSidebar,
      takebackOrder,
      editableState,
      takebackTableRef,
      inputCheckbox,
      selectRow,
      calculateCancelPrice,
      isValidAmount,
      takebackAmount,
      isValid,
    } = useTakebackOrderSidebar()

    const {
      resolveMoney,
    } = useResolveValue()

    const orderFields = [
      { key: "selected", label: "선택" },
      { key: "order_no", label: "주문번호" },
      { key: "product_name", label: "상품명" },
      { key: "state", label: "상태" },
      { key: "amount", label: "가격" },
    ]

    const causeOptions = [
      { text: "예", value: true },
      { text: "아니오", value: false },
    ]

    const useBankingOptions = [
      { text: "예", value: true },
      { text: "아니오", value: false },
    ]

    return {
      state,
      turnOffSidebar,
      takebackOrder,
      resolveMoney,
      orderFields,
      editableState,
      inputCheckbox,
      takebackTableRef,
      selectRow,
      causeOptions,
      calculateCancelPrice,
      isValidAmount,
      takebackAmount,
      isValid,
      useBankingOptions,
    }
  },
})
</script>

<style scoped>
.tg-table-result {
  border-top: 5px double;
}
</style>
