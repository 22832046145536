<template>
  <TagbyDetailLayout :hasLoadError="state.hasLoadError">
    <template #content>
      <TagbyTabs :tabList="tabList">
        <template #tab(order-group)>
          <OrderGroupCard />
        </template>

        <template #tab(order)>
          <OrderList />
        </template>

        <template #tab(delivery)>
          <DeliveryList />
        </template>

        <template #tab(charge)>
          <ChargeCard />
        </template>

        <template #tab(pay)>
          <PayCard />
        </template>

      </TagbyTabs>
    </template>

    <template #side>
      <ActionCard />
      <TagbyTimelinesV3
        :timelineList="state.timelineList"
      />
    </template>

    <template #hidden>
      <ErrorReasonModal />
      <UpdateAddressSidebar />
      <UpdateTrackingSidebar />
      <ResetTrackingModal />
      <UpdateCustomsIdSidebar />
      <CreateMemoSidebar />
      <SetPaidModal />
      <ResetPasswordModal />
      <UpdateShpSidebar />
      <RevertCanceledOrderGroupModal />
      <CancelOrderSidebar />
      <TakebackOrderSidebar />
      <ExchangeOrderSidebar />
      <ChargeAndPaySidebar />
      <CancelAccountModal />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { getRouterIdx } from '@/utils/router'
import TagbyTabs from '@/components/TagbyTabs.vue'
import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'
import TagbyTimelinesV3 from '@/components/TagbyTimelinesV3.vue'
import ActionCard from './components/ActionCard/Main.vue'
import useFetch from './useFetch'
import ErrorReasonModal from './components/Modals/ErrorReasonModal.vue'
import state from './state'
import getters from './getters'
import useState from './useState'
import OrderGroupCard from './components/OrderGroupCard/Main.vue'
import OrderList from './components/OrderList/Main.vue'
import DeliveryList from './components/DeliveryList/Main.vue'
import ChargeCard from './components/ChargeCard/Main.vue'
import PayCard from './components/PayCard/Main.vue'
import UpdateAddressSidebar from './components/Sidebars/UpdateAddressSidebar.vue'
import UpdateTrackingSidebar from './components/Sidebars/UpdateTrackingSidebar.vue'
import ResetTrackingModal from './components/Modals/ResetTrackingModal.vue'
import UpdateCustomsIdSidebar from './components/Sidebars/UpdateCustomsIdSidebar.vue'
import CreateMemoSidebar from './components/Sidebars/CreateMemoSidebar.vue'
import SetPaidModal from './components/Modals/SetPaidModal.vue'
import ResetPasswordModal from './components/Modals/ResetPasswordModal.vue'
import UpdateShpSidebar from './components/Sidebars/UpdateShpSidebar.vue'
import RevertCanceledOrderGroupModal from './components/Modals/RevertCanceledOrderGroupModal.vue'
import CancelOrderSidebar from './components/Sidebars/CancelOrderSidebar.vue'
import TakebackOrderSidebar from './components/Sidebars/TakebackOrderSidebar.vue'
import ExchangeOrderSidebar from './components/Sidebars/ExchangeOrderSidebar.vue'
import ChargeAndPaySidebar from './components/Sidebars/ChargeAndPaySidebar.vue'
import CancelAccountModal from './components/Modals/CancelAccountModal.vue'

export default defineComponent({
  components: {
    TagbyDetailLayout,
    TagbyTabs,
    TagbyTimelinesV3,
    ErrorReasonModal,
    OrderGroupCard,
    OrderList,
    DeliveryList,
    ChargeCard,
    PayCard,
    ActionCard,
    UpdateAddressSidebar,
    UpdateTrackingSidebar,
    ResetTrackingModal,
    UpdateCustomsIdSidebar,
    CreateMemoSidebar,
    SetPaidModal,
    ResetPasswordModal,
    UpdateShpSidebar,
    RevertCanceledOrderGroupModal,
    CancelOrderSidebar,
    TakebackOrderSidebar,
    ExchangeOrderSidebar,
    ChargeAndPaySidebar,
    CancelAccountModal,
  },
  setup() {
    const {
      resetState,
    } = useState()
    resetState()

    state.idx = getRouterIdx()

    const tabList = [
      { key: 'order-group', title: '주문그룹' },
      { key: 'order', title: '주문' },
      { key: 'delivery', title: '배송' },
      { key: 'charge', title: '과금' },
      { key: 'pay', title: '결제' },
    ]

    const {
      getDetail,
      getOrderMember,
      getMyshop,
      getOrderList,
      getDeliveryList,
      getChargeList,
      getPayList,
      getTimelineList,
    } = useFetch()

    getDetail()
    getOrderList()
    getOrderMember()
    getMyshop()
    getDeliveryList()
    getChargeList()
    getPayList()
    getTimelineList()

    return {
      tabList,
      state,
      getters,
    }
  },
})
</script>
