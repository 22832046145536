import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getTimelineList,
    getDeliveryList,
    getOrderList,
  } = useFetch()

  const turnOnSidebar = delivery => {
    state.updateTrackingSidebar.isVisible = true
    state.updateTrackingSidebar.targetDelivery.idx = delivery.idx
    state.updateTrackingSidebar.targetDelivery.shipment_company = delivery.shipment_company
    state.updateTrackingSidebar.targetDelivery.tracking_no = delivery.tracking_no
  }

  const turnOffSidebar = () => {
    state.updateTrackingSidebar.isVisible = false
  }

  const updateTracking = () => {
    state.updateTrackingSidebar.isSaving = true
    return api.updateTracking({
      order_group_idx: state.idx,
      delivery_idx: state.updateTrackingSidebar.targetDelivery.idx,
      shipment_company: state.updateTrackingSidebar.targetDelivery.shipment_company,
      tracking_no: state.updateTrackingSidebar.targetDelivery.tracking_no,
    }).then(() => {
      makeToast('primary', '송장 변경에 성공했습니다')
      getTimelineList()
      getDeliveryList()
      getOrderList()
      turnOffSidebar()
    }).catch(err => {
      makeToast('danger', '송장 변경에 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.updateTrackingSidebar.isSaving = false
    })
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    updateTracking,
  }
}
