import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getTimelineList,
    getDeliveryList,
  } = useFetch()

  const turnOnSidebar = delivery => {
    state.updateAddressSidebar.isVisible = true
    state.updateAddressSidebar.targetDelivery.idx = delivery.idx
    state.updateAddressSidebar.targetDelivery.address = delivery.address
    state.updateAddressSidebar.targetDelivery.address_detail = delivery.address_detail
    state.updateAddressSidebar.targetDelivery.message = delivery.message
    state.updateAddressSidebar.targetDelivery.recipient_name = delivery.recipient_name
    state.updateAddressSidebar.targetDelivery.phone = delivery.phone
  }

  const turnOffSidebar = () => {
    state.updateAddressSidebar.isVisible = false
  }

  const updateAddress = () => {
    state.updateAddressSidebar.isSaving = true
    return api.updateAddress({
      order_group_idx: state.idx,
      delivery_idx: state.updateAddressSidebar.targetDelivery.idx,
      address: state.updateAddressSidebar.targetDelivery.address,
      address_detail: state.updateAddressSidebar.targetDelivery.address_detail,
      message: state.updateAddressSidebar.targetDelivery.message,
      recipient_name: state.updateAddressSidebar.targetDelivery.recipient_name,
      phone: state.updateAddressSidebar.targetDelivery.phone,
    }).then(() => {
      makeToast('primary', '주소 변경에 성공했습니다')
      getTimelineList()
      getDeliveryList()
      turnOffSidebar()
    }).catch(err => {
      makeToast('danger', '주소 변경에 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.updateAddressSidebar.isSaving = false
    })
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    updateAddress,
  }
}
