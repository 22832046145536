import axiosFrame from "@/utils/axiosFrame"

export default {
  getAdminMe: axiosFrame("get", "/manage/admin/me/", null),
  getDetail: axiosFrame("get", "/manage/order-group/detail/", null),
  getOrderList: axiosFrame("get", "/manage/order-group/order-list/", null),
  getOrderMember: axiosFrame("get", "/manage/order-group/order-member/", null),
  getMyshop: axiosFrame("get", "/manage/order-group/myshop/", null),
  getDeliveryList: axiosFrame("get", "/manage/order-group/delivery-list/", null),
  getChargeList: axiosFrame("get", "/manage/order-group/charge-list/", null),
  getPayList: axiosFrame("get", "/manage/order-group/pay-list/", null),
  getTimelineList: axiosFrame("get", "/manage/history/timeline-list/", null),
  updateAddress: axiosFrame("post", "/manage/order-group/update-address/", null),
  updateTracking: axiosFrame("post", "/manage/order-group/update-tracking/", null),
  resetTracking: axiosFrame("post", "/manage/order-group/reset-tracking/", null),
  updateCustomeId: axiosFrame("post", "/manage/order-group/update-customs-id/", null),
  createMemo: axiosFrame("post", "/manage/memo/create-memo/", null),
  setPaid: axiosFrame("post", "/manage/order-group/set-paid/", null),
  resetPassword: axiosFrame("post", "/manage/order-group/reset-password/", null),
  updateShp: axiosFrame("post", "/manage/order-group/update-shp/", null),
  revertCanceledOrderGroup: axiosFrame("post", "/manage/order-group/revert-canceled-order-group/", null),
  cancelOrder: axiosFrame("post", "/manage/order-group/cancel-order/", null),
  takebackOrder: axiosFrame("post", "/manage/order-group/takeback-order/", null),
  exchangeOrder: axiosFrame("post", "/manage/order-group/exchange-order/", null),
  calculateCancelPrice: axiosFrame("post", "/manage/order-group/calculate-cancel-price/", null),
  calculateTakebackPrice: axiosFrame("post", "/manage/order-group/calculate-takeback-price/", null),
  getProduct: axiosFrame("get", "/manage/order-group/product/", null),
  getProductOptionList: axiosFrame("get", "/manage/order-group/product-option-list/", null),
  chargeAndPay: axiosFrame("post", "/manage/order-group/charge-and-pay/", null),
  updateAccountCanceled: axiosFrame("post", "/manage/order-group/update-account-canceled/", null),
}
