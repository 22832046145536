<template>
  <TagbySidebarWithButton
    :visible="state.updateAddressSidebar.isVisible"
    :isSaving="state.updateAddressSidebar.isSaving"
    :isValid="true"
    title="주소 변경"
    width="30%"
    @ok="updateAddress"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <TagbyInputRow label="주소">
      <BFormInput v-model="state.updateAddressSidebar.targetDelivery.address" />
    </TagbyInputRow>

    <TagbyInputRow label="주소 상세">
      <BFormInput v-model="state.updateAddressSidebar.targetDelivery.address_detail" />
    </TagbyInputRow>

    <TagbyInputRow label="메시지">
      <BFormTextarea v-model="state.updateAddressSidebar.targetDelivery.message" />
    </TagbyInputRow>

    <TagbyInputRow label="이름">
      <BFormInput v-model="state.updateAddressSidebar.targetDelivery.recipient_name" />
    </TagbyInputRow>

    <TagbyInputRow label="연락처">
      <BFormInput v-model="state.updateAddressSidebar.targetDelivery.phone" />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import state from '../../state'
import useUpdateAddress from '../../useUpdateAddress'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormInput,
    BFormTextarea,
  },
  setup() {
    const {
      turnOffSidebar,
      updateAddress,
    } = useUpdateAddress()

    return {
      state,
      turnOffSidebar,
      updateAddress,
    }
  },
})
</script>
