<template>
  <TagbySidebarWithButton
    :visible="state.cancelOrderSidebar.isVisible"
    :isSaving="state.cancelOrderSidebar.isSaving"
    :isValid="isValid"
    title="주문 취소"
    width="50%"
    cancelText="닫기"
    @ok="cancelOrder"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <BTable
      ref="cancelTableRef"
      :fields="orderFields"
      :items="state.orderList"
      selectable
      responsive
      select-mode="multi"
      @row-selected="selectRow"
    >
      <template #cell(selected)="data">
        <BFormCheckbox
          :checked="data.rowSelected"
          :disabled="!editableState.includes(data.item.state)"
          @change="inputCheckbox(data)"
        />
      </template>

      <template #cell(amount)="data">
        {{ resolveMoney(data.value) }}
      </template>

      <template
        v-slot:custom-foot
      >
        <BTr class="tg-table-result">
          <BTd>상품총액</BTd>
          <BTd
            colspan="4"
            class="text-right text-success"
          >
            {{ resolveMoney(state.cancelOrderSidebar.cancelAmount) }}
          </BTd>
        </BTr>
        <BTr>
          <BTd>배송비</BTd>
          <BTd
            colspan="4"
            class="text-right"
            :class="state.cancelOrderSidebar.cancelShippingFee >= 0 ? 'text-success': 'text-danger'"
          >
            {{ resolveMoney(state.cancelOrderSidebar.cancelShippingFee) }}
          </BTd>
        </BTr>
        <BTr class="tg-table-result">
          <BTd>취소총액</BTd>
          <BTd
            colspan="4"
            class="text-right"
          >
            {{ resolveMoney(state.cancelOrderSidebar.cancelAmount + state.cancelOrderSidebar.cancelShippingFee) }}
          </BTd>
        </BTr>
      </template>
    </BTable>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BTable,
  BFormCheckbox,
  BTr,
  BTd,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import state from '../../state'
import useCancelOrderSidebar from '../../useCancelOrderSidebar'
import useResolveValue from '@/utils/resolveValue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BTable,
    BFormCheckbox,
    BTr,
    BTd,
  },
  setup() {
    const {
      turnOffSidebar,
      cancelOrder,
      editableState,
      cancelTableRef,
      inputCheckbox,
      selectRow,
      isValid,
    } = useCancelOrderSidebar()

    const {
      resolveMoney,
    } = useResolveValue()

    const orderFields = [
      { key: 'selected', label: '선택' },
      { key: 'order_no', label: '주문번호' },
      { key: 'product_name', label: '상품명' },
      { key: 'state', label: '상태' },
      { key: 'amount', label: '가격' },
    ]

    return {
      state,
      turnOffSidebar,
      cancelOrder,
      resolveMoney,
      orderFields,
      editableState,
      inputCheckbox,
      cancelTableRef,
      selectRow,
      isValid,
    }
  },
})
</script>

<style scoped>
.tg-table-result {
  border-top: 5px double;
}
</style>
