<template>
  <div>
    <span class="text-muted">
      {{ label }}:
    </span>
    <span>
      {{ value }} <TagbyCopyIcon
        v-if="copiable"
        :value="value"
      />
    </span>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'

export default defineComponent({
  components: {
    TagbyCopyIcon,
  },
  props: {
    label: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    copiable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

  },
})
</script>
