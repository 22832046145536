import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getTimelineList,
    getOrderList,
    getDetail,
    getPayList,
  } = useFetch()

  const turnOnModal = () => {
    state.setPaidModal.isVisible = true
  }

  const turnOffModal = () => {
    state.setPaidModal.isVisible = false
  }

  const setPaid = () => api.setPaid({
    order_group_idx: state.idx,
  }).then(() => {
    makeToast('primary', '결제완료로 변경에 성공했습니다')
    getTimelineList()
    getOrderList()
    getDetail()
    getPayList()
  }).catch(err => {
    makeToast('danger', '결제완료로 변경에 실패했습니다')
    turnOnErrorReasonModal(err)
  })

  return {
    turnOnModal,
    turnOffModal,
    setPaid,
  }
}
