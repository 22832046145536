export default () => ({
  hasLoadError: false,
  isVisibleErrorReasonModal: false,
  adminPermission: null,
  idx: null,
  orderGroup: {
    idx: null,
    order_no: null,
    amount: null,
    shipping_fee: null,
    state: null,
  },
  orderMember: {
    idx: null,
    type: null,
    name: null,
    phone: null,
  },
  orderStore: {
    idx: null,
    name: null,
    url_path: null,
  },
  orderList: [],
  deliveryList: [],
  chargeList: [],
  payList: [],
  timelineList: [],
  updateAddressSidebar: {
    isVisible: false,
    targetDelivery: {
      idx: null,
      address: null,
      address_detail: null,
      message: null,
      recipient_name: null,
      phone: null,
    },
    isSaving: false,
  },
  updateTrackingSidebar: {
    isVisible: false,
    targetDelivery: {
      idx: null,
      shipment_company: null,
      tracking_no: null,
    },
    isSaving: false,
  },
  resetTrackingModal: {
    isVisible: false,
    targetDelivery: {
      idx: null,
    },
  },
  updateCustomsIdSidebar: {
    isVisible: false,
    targetDelivery: {
      idx: null,
      personal_customs_id: null,
    },
    isSaving: false,
  },
  createMemoSidebar: {
    isVisible: false,
    text: null,
    isSaving: false,
  },
  setPaidModal: {
    isVisible: false,
  },
  resetPasswordModal: {
    isVisible: false,
  },
  updateShpSidebar: {
    isVisible: false,
    isSaving: false,
    name: null,
    phone: null,
  },
  revertCanceledOrderGroupModal: {
    isVisible: false,
  },
  cancelOrderSidebar: {
    isVisible: false,
    isSaving: false,
    cancelAmount: 0,
    cancelShippingFee: 0,
  },
  takebackOrderSidebar: {
    isVisible: false,
    isSaving: false,
    cancelAmount: 0,
    cancelShippingFee: 0,
    causeCustomer: true,
    customerShippingFee: null,
    useBankingCancelShippingFee: true,
  },
  exchangeOrderSidebar: {
    isVisible: false,
    isSaving: false,
    exchangedPrice: null,
    customerShippingFee: null,
    targetOrder: {
      idx: null,
      product_idx: null,
      product_name: null,
      product_price: null,
      order_no: null,
      options: [],
      amount: null,
      qty: null,
    },
    optionList: [],
    selectedContentByCategory: {},
    product: {
      idx: null,
      name: null,
      price: null,
    },
  },
  chargeAndPaySidebar: {
    isVisible: false,
    isSaving: false,
    purpose: "SHIPPING",
    isCancel: false,
    amount: null,
  },
  cancelAccountModal: {
    isVisible: false,
  },
})
