<template>
  <TagbyPrimaryModal
    :visible="state.resetPasswordModal.isVisible"
    title="비밀번호 초기화"
    content="해당 주문그룹의 비밀번호가 123456으로 변경됩니다. 변경하시겠습니까?"
    @ok="resetPassword"
    @hidden="turnOffModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import useResetPassword from '../../useResetPassword'
import state from '../../state'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    const {
      resetPassword,
      turnOffModal,
    } = useResetPassword()

    return {
      state,
      resetPassword,
      turnOffModal,
    }
  },
})
</script>
