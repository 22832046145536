import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getTimelineList,
    getDeliveryList,
    getOrderList,
  } = useFetch()

  const turnOnModal = delivery => {
    state.resetTrackingModal.isVisible = true
    state.resetTrackingModal.targetDelivery.idx = delivery.idx
  }

  const turnOffModal = () => {
    state.resetTrackingModal.isVisible = false
  }

  const resetTracking = () => api.resetTracking({
    order_group_idx: state.idx,
    delivery_idx: state.resetTrackingModal.targetDelivery.idx,
  }).then(() => {
    makeToast('primary', '송장 초기화에 성공했습니다')
    getDeliveryList()
    getOrderList()
    getTimelineList()
  }).catch(err => {
    makeToast('danger', '송장 초기화에 실패했습니다')
    turnOnErrorReasonModal(err)
  })

  return {
    turnOnModal,
    turnOffModal,
    resetTracking,
  }
}
