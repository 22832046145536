import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getTimelineList,
    getDetail,
    getOrderList,
    getDeliveryList,
    getPayList,
    getChargeList,
  } = useFetch()

  const turnOnModal = () => {
    state.revertCanceledOrderGroupModal.isVisible = true
  }

  const turnOffModal = () => {
    state.revertCanceledOrderGroupModal.isVisible = false
  }

  const revertCanceledOrderGroup = () => api.revertCanceledOrderGroup({
    order_group_idx: state.idx,
  }).then(() => {
    makeToast('primary', '취소된 주문 복구에 성공했습니다')
    getTimelineList()
    getDetail()
    getOrderList()
    getDeliveryList()
    getPayList()
    getChargeList()
  }).catch(err => {
    makeToast('danger', '취소된 주문 복구에 실패했습니다')
    turnOnErrorReasonModal(err)
  })

  return {
    turnOnModal,
    turnOffModal,
    revertCanceledOrderGroup,
  }
}
