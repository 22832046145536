<template>
  <TagbyDangerModal
    :visible="state.cancelAccountModal.isVisible"
    title="무통장 취소완료 처리"
    content="취소대기중인 무통장 결제가 취소완료로 변경됩니다. 무통장 환불은 시스템상을 통해 입금해주지 않으니, 담당자가 환불을 완료한 후에 해당 기능을 사용해주세요."
    @ok="updateAccountCanceled"
    @hidden="turnOffModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import useCancelAccountModal from '../../useCancelAccountModal'
import state from '../../state'

export default defineComponent({
  components: {
    TagbyDangerModal,
  },
  setup() {
    const {
      updateAccountCanceled,
      turnOffModal,
    } = useCancelAccountModal()

    return {
      state,
      updateAccountCanceled,
      turnOffModal,
    }
  },
})
</script>
