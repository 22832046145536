<template>
  <TagbySidebarWithButton
    :visible="state.updateCustomsIdSidebar.isVisible"
    :isSaving="state.updateCustomsIdSidebar.isSaving"
    :isValid="true"
    title="개인통관번호 수정"
    width="30%"
    @ok="updateCustomeId"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <TagbyInputRow label="개인통관번호">
      <BFormInput v-model="state.updateCustomsIdSidebar.targetDelivery.personal_customs_id" />
    </TagbyInputRow>

  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import state from '../../state'
import useUpdateCustomsId from '../../useUpdateCustomsId'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormInput,
    BFormTextarea,
  },
  setup() {
    const {
      turnOffSidebar,
      updateCustomeId,
    } = useUpdateCustomsId()

    return {
      state,
      turnOffSidebar,
      updateCustomeId,
    }
  },
})
</script>
